<template>
  <div>
    <b-card no-body class="mt-1">
      <b-card-header class="p-1">
        <b-card-title>{{$t('curlang')=='en'?'Create Comments':'Crear Comentarios'}}</b-card-title>
      </b-card-header>
      <b-card-body>
          <b-form @submit.prevent="insertComment">
        <b-row>
          <b-col lg="4" md="12" sm="12" cols="12">
            <b-form-group :label="$t('curlang')=='en'?'Select a Category':'Selecciona la Categoria'">
              <v-select
                v-model="list.selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="option"
                :searchable="false"
              />
            </b-form-group>
          </b-col>
            <b-col lg="4" md="12" sm="12" cols="12" v-if="list.selected.value==1">
            <b-form-group :label="$t('curlang')=='en'?'Name Category':'Nombre de la Categoria'">
          <b-form-input
            v-model="list.category"
            :placeholder="$t('curlang')=='en'?'Name Category':'Nombre de la Categoria'"
             />
            </b-form-group>
          </b-col>
          <b-col lg="4" md="12" sm="12" cols="12">
            <b-form-group :label="$t('curlang')=='en'?'Comment English':'Comentario en Ingles'">
          <b-form-textarea
            v-model="list.comment"
            :placeholder="$t('curlang')=='en'?'Comment English':'Comentario en Ingles'"
             rows="3"
             />
            </b-form-group>
          </b-col>
           <b-col lg="4" md="12" sm="12" cols="12">
            <b-form-group :label="$t('curlang')=='en'?'Comment Spanish':'Comentario en Español'">
          <b-form-textarea
            v-model="list.commentes"
            :placeholder="$t('curlang')=='en'?'Comment Spanish':'Comentario en Español'"
             rows="3"
             />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
              <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
           {{$t('questregister.save')}}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
           {{$t('questregister.reset')}}
        </b-button>
      </b-col>
        </b-row>
          </b-form>
      </b-card-body>
    </b-card>

       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Comments List':'Lista de Comentarios'}}</b-card-title>
            </div>
            <div>
            <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
        </div>
        </b-card-header>
        <b-table
         :busy="busy"
         :items="items"
         responsive
        striped
        hover
         selectable
      select-mode="range"
       :per-page="perPage"
      :current-page="currentPage"
          :filter="filter"
        :filter-included-fields="filterOn"
            @filtered="onFiltered">
              <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
        </b-table>
         <b-col
      cols="12"
    >
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BForm,
  BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BFormInput,BInputGroup,BInputGroupAppend,BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import NotificationService from "@/services/NotificationService";
import SellerService from "@/services/SellerService";
import Ripple from 'vue-ripple-directive'
export default {
    directives:{
        Ripple
    },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BFormTextarea,
    BButton,
    BForm,
    BFormInput,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,BInputGroup,
    BInputGroupAppend,BPagination
  },
  data() {
    return {
        list:{
        category:null,
        comment:null,
        commentes:null,
        selected: {title:this.$t('curlang')=='en'?'Select a Category':'Selecciona una Categoria',value:null},
        },
        option: [],
            items:[],
          perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      busy:true
    }
  },
  mounted(){
    this.getCategories()
    this.getAllComments()
      this.totalRows = this.items.length
  },
  methods: {
        onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
       getCategories:async function(){
      const ct=(await SellerService.getCategories(JSON.parse(localStorage.getItem("auth")).discordId)).data
       this.option.push({title:'Crear Nueva Categoria',value:1})
        for (let x = 0;x<ct.length;x++){
            this.option.push({title:ct[x].category,value:ct[x]._id})
        }
    },
    getAllComments:async function(){
      const ac=(await NotificationService.getAllComments(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.items=ac
       this.totalRows = this.items.length
      this.busy=false
    },
      insertComment:async function(){
        if (this.list.selected.value && this.list.comment && this.list.commentes) {
          console.log(this.list.selected);
            if (this.list.selected.value!=1) {
              this.list.category=this.list.selected.title
            }
            const cm=(await NotificationService.insertComment(JSON.parse(localStorage.getItem("auth")).discordId,this.list))
             this.$swal({
          title: "",
          text: "Comments Created Successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
         

        }
        else{
             this.$swal({
            title: 'Insert all fields',
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
    }
      }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>